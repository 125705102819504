<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-6 pt-lg-5">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">广州国企消费帮扶<br/>数据统计中心</h1>
              <p class="text-lead text-white ls-2" v-if="model.status == 0">您的账号还在审核中...</p>
              <p class="text-lead text-danger ls-2" v-else-if="model.status == 2">您的账号审核不通过</p>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
        <div class="row justify-content-center mb-3" v-if="model.status == 2 && !!model.remark">
            <div class="col-lg-5 col-md-7">
                <div class="card bg-secondary border-0 mb-0">
                    <div class="card-body px-lg-3 py-lg-3" v-loading="loading">
                        <div class="text-center text-muted mb-4">
                            <span class="ls-2">审核意见</span>
                        </div>
                        <p>{{ model.remark }}</p>
                    </div>
                </div>
            </div>
        </div>
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-3" v-loading="loading">
              <div class="text-center text-muted mb-4">
                <span class="ls-2">账号详情</span>
              </div>
              <el-form ref="regform" :model="model" label-width="auto" label-position="top" v-loading="loading">
                <div class="row">
                  <div class="col-12">
                    <h6 class="text-muted">账号名称</h6>
                    <h3 class="mt-n2">{{ model.username }}</h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <h6 class="text-muted">公司名称</h6>
                    <h3 class="mt-n2" v-if="!editing">{{ model.company_name }}</h3>
                    <el-form-item prop="company_name" :rules="[{required:true, message:'请填写公司名称'}]" v-else>
                      <el-input v-model="model.company_name"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <h6 class="text-muted">企业性质</h6>
                    <h3 class="mt-n2" v-if="!editing">{{ model.org_type }}</h3>
                    <el-form-item prop="org_type" :rules="[{required:true, message:'请选择企业性质'}]" v-if="editing">
                      <el-select v-model="model.org_type" placeholder="选择企业性质">
                        <el-option v-for="item in orgs" :key="item.name" :label="item.name" :value="item.name">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
                <div class="row" v-if="model.org_type === city_org">
                  <div class="col-12">
                    <h6 class="text-muted">国企单位</h6>
                    <h3 class="mt-n2" v-if="!editing">{{ model.org_name }}</h3>
                    <el-form-item prop="org_name" :rules="[{required:true, message:'请选择国企单位'}]" v-if="editing">
                      <el-select v-model="model.org_name" placeholder="选择国企单位">
                        <el-option v-for="item in orgNames" :key="item" :label="item" :value="item">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
                <div class="row" v-else-if="model.org_type === ditrict_org">
                  <div class="col-12">
                    <h6 class="text-muted">所属地区</h6>
                    <h3 class="mt-n2" v-if="!editing">{{ model.org_district }}</h3>
                    <el-form-item prop="org_district" v-if="editing">
                      <el-select v-model="model.org_district" placeholder="选择地区">
                        <el-option v-for="item in orgDistricts" :key="item" :label="item" :value="item">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
                <div class="row" v-if="model.org_type !== city_org">
                  <div class="col-12">
                    <h6 class="text-muted">国企单位</h6>
                    <h3 class="mt-n2" v-if="!editing">{{ model.org_name }}</h3>
                    <el-form-item prop="org_name" :rules="[{required:true, message:'请填写所属国企单位'}]" v-if="editing">
                      <el-input v-model="model.org_name" placeholder="所属国企单位" required></el-input>
                      <small class="text-muted"><em>* 请填所属国企的政府部门登记核准名称的全称</em></small>
                    </el-form-item>
                  </div>
                </div>
                <el-divider></el-divider>
                <div class="row">
                  <div class="col-12">
                    <h6 class="text-muted">联系人</h6>
                    <h3 class="mt-n2" v-if="!editing">{{ model.contact_name }}</h3>
                    <el-form-item prop="contact_name" :rules="[{required:true, message:'请填写联系人姓名'}]" v-else>
                      <el-input v-model="model.contact_name"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <h6 class="text-muted">联系电话</h6>
                    <h3 class="mt-n2" v-if="!editing">{{ model.contact_phone }}</h3>
                    <el-form-item prop="contact_phone" :rules="[{required:true, message:'请填写联系电话'}]" v-else>
                      <el-input v-model="model.contact_phone"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <h6 class="text-muted">联系邮箱地址</h6>
                    <h3 class="mt-n2" v-if="!editing">{{ model.contact_email }}</h3>
                    <el-form-item prop="contact_email" :rules="[{required:true, message:'请填写邮箱地址'}]" v-else>
                      <el-input v-model="model.contact_email"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="text-center" v-if="model.status == 2 && !editing">
                  <button type="button" class="btn btn-primary mt-4 ls-3" @click="edit">修改资料</button>
                </div>
                <div class="text-center" v-if="editing">
                  <button type="button" class="btn btn-primary mt-4 ls-3" @click="submit">提交</button>
                </div>
              </el-form>
            </div>
          </div>
          <div class="row mt-3">
          </div>
        </div>
      </div>
    </div>
    <modal :show.sync="modal" @close="leave">
        <h6 slot="header" class="modal-title" id="modal-title-default">提交成功</h6>
        <p>统计中心工作人员将在1个工作日内对信息进行审核。请等待管理员审核。</p>
        <template slot="footer">
            <base-button type="primary" @click="leave">知道了</base-button>
        </template>
    </modal>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        loading: false,
        editing: false,
        modal: false,
        model: {
          username: '',
          company_name: '',
          org_type: '',
          org_name: '',
          org_district: '',
          contact_name: '',
          contact_phone: '',
          contact_email: ''
        },
        city_org: '市属国有企业',
        ditrict_org: '区属国有企业',
        orgs: []
      }
    },
    computed: {
      loginedUser() {
        return this.$store.state.user.user
      },
      orgNames() {
        const cityOrg = this.orgs.find(x => x.name === this.city_org)
        if (cityOrg) return cityOrg.companies
        return []
      },
      orgDistricts() {
        const districtOrg = this.orgs.find(x => x.name === this.ditrict_org)
        if (districtOrg) return districtOrg.districts
        return []
      }
    },
    methods: {
        fetchData() {
            this.loading = true
            if (!this.$store.state.org.org) {
                this.$router.push('/login')
            }
            this.axios.get(`orgs/${this.$store.state.org.org.id}/edit`).then((res) => {
                this.model = res.data.data
                this.loading = false
            }).catch((err) => {
                this.$notify({type:'warning', message: '加载数据失败: ' + err.message});
                this.loading = false
            })
        },
        edit () {
            this.editing = true
        },
        submit() {
            this.$refs['regform'].validate((valid) => {
                if (valid) {
                  this.loading = true;
                  this.axios.put('orgs/' + this.model.id, this.model).then((res) => {
                    if (res.data.errcode == 0) {
                        this.modal = true;
                    }
                  this.loading = false;
                  }).catch((err) => {
                    this.$notify({type:'warning', message: 'Failed: ' + err.message});
                    this.loading = false;
                  })
                } else {
                  console.log('form invalid')
                  return false;
                }
            })
        },
        leave() {
          this.fetchData()
          this.editing = false
          this.modal = false
        }
    },
    mounted() {
        this.fetchData()
        this.axios.get('orglib').then((res) => {
            this.orgs = res.data
        })
    }
  };
</script>
<style scoped>
  .el-input, .el-select, .el-autocomplete {
        width: 100%;
    }
</style>
